.research-page {
}

.research-image-box {
  position: relative;
  height: 600px;
}

.research-image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.research-info-box {
  max-width: 1500px;
  margin: 0 auto;
}

.research-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #f5f9ff;
  font-weight: 700;
  font-size: 60px;
}

.table-box {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
  border-collapse: collapse;
}

tr {
  height: 30px;
}

.research-table {
}

.research-table thead {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  background-color: #222a35;
  color: white;
}

.research-table tbody tr {
  cursor: pointer;
  text-align: center;
}

.research-table tbody tr:hover {
  background-color: #ddebf7;
  opacity: 0.9;
  cursor: pointer;
}

.item-tr:nth-child(even) {
  background-color: #ddebf7;
}

.item-div {
  cursor: pointer;
}

.post-button {
  align-self: flex-end;
  font-weight: 400;
  font-size: 18px;
  padding: 10px 40px;
  border-radius: 5px;
  color: white;
  background-color: #222a35;
  margin: 40px 0;
  cursor: pointer;
}

.pagenation {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 50px 0;
}

.pagenation div {
  font-size: 24px;
  font-weight: 200;
  color: #d9d9d9;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 10000000;
}

.reseach-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: gray;
}

.reseach-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(206, 206, 206);
  line-height: 1;
}
.modal-title {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 80px;
}

.modal-input-box {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
}

.modal-input-title {
  width: 100px;
  font-size: 20px;
  text-align: right;
}

.modal-input {
  padding: 10px;
}
.modal-file-upload {
  text-align: center;
  justify-content: center;
}
.modal-file-upload-box {
  background-color: white;
  border-radius: 10px;
  padding: 40px;
  width: 70%;
  margin-top: 100px;
}

.modal-file-upload-text1 {
  font-size: 30px;
  font-weight: 700;
  margin: 100px 0 10px 0;
}

.modal-file-upload-text2 {
  font-size: 20px;
  font-weight: 700;
  color: gray;
  margin: 0 0 30px 0;
}

.modal-file-upload {
  border: 2px dashed #e8e8e8e8;
  padding: 20px;
  cursor: pointer;
}

.modal-file-upload-text3 {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 10px;
}

.modal-file-upload-text4 {
  font-size: 20px;
  font-weight: 700;
  color: gray;
  text-align: center;
  margin-bottom: 10px;
}

.modal-file-upload-text5 {
  font-size: 20px;
  font-weight: 700;
  color: blue;
  text-align: center;
  margin-bottom: 50px;
}

.modal-button-box {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 30px 0 0 0;
}

.modal-cancel-button {
  padding: 20px 40px;
  border: 3px solid #e8e8e8e8;
  font-size: 20px;
  font-weight: 700;
  border-radius: 10px;
  cursor: pointer;
}

.modal-upload-button {
  padding: 20px 40px;
  background-color: blue;
  font-size: 20px;
  font-weight: 700;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}

.post-box {
  border-top: 3px solid #214c58;
  line-height: 1;
}

.post-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 700;
  color: #222a35;
  border-bottom: 3px solid #ceccd7;
  margin-bottom: 10px;
  padding: 30px 0;
}

.post-file-box {
  border-top: 3px solid #ceccd7;
  border-bottom: 3px solid #ceccd7;
  padding: 200px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.post-file-name {
  font-size: 24px;
  font-weight: 700;
  color: #222a35;
  cursor: pointer;
}

.post-button-box {
  display: flex;
  justify-content: flex-end;
  margin: 50px 0;
}

.post-back-button {
  color: white;
  font-size: 20px;
  padding: 20px 40px;
  background-color: #222a35;
  border-radius: 5px;
  cursor: pointer;
}

.item-div-name {
  white-space: nowrap;
}

@media screen and (max-width: 480px) {
  .reaserach-head {
    visibility: hidden;
  }

  .item-tr {
    display: none;
  }

  .item-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    /* justify-content: space-between; */
    padding: 10px 10px;
    border-top: 0.5px solid #222a35;
    border-bottom: 0.5px solid #222a35;
  }
}

@media screen and (min-width: 480px) {
  .item-div {
    display: none;
  }
}