.section {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
}

.section-title {
    margin-bottom: 50px !important;
}

.section-content {
  white-space: pre;
}

.section-content ul li {
  margin-bottom: 15px;
}

.PageWrapper {
    width: 100%;
    height: 100%;
    padding: 100px 0 100px 0;
    box-sizing: border-box;
}

.PageInnerWrapper {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    padding: 0 1rem 100px 1rem;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
}

