.headerWrapper{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
    height: 100px;
}

.headerWrapper.toggled{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: #FFFFFF;
    height: 80px;
    transition: background-color 0.5s, height 0.5s;
    box-shadow: 8px 8px 2px 1px rgba(0, 0, 0, .2);
}

.mainHeader{
    position: relative;
    width: 100%;
}

.logo {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 20px;
    max-width:120px;
    transition: opacity 0.1s;
}

.logo.toggled {
    opacity: 1;
    position: absolute;
    left: 0;
    top: 20px;
    transition: opacity 1s;
}

@media (max-width: 480px) {
    .headerWrapper .desktop {
        display: none
    }
    
    .logo {
        display: none
    }
}


@media (min-width: 480px) {
    .headerWrapper .mobile {
        display: none
    }
}