.slide-up {
    animation: slideup 1s ease;
}

@keyframes slideup {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-width: 480px) {

    .leftTextBox {
        background-color: rgba(0, 35, 102, 0.8);
        padding: 2.3em 1.2em;
        max-width: 400px;
        box-sizing: border-box;
        width: 100%;
        min-width: 200px;
        white-space: pre-wrap;
        z-index: 1;
        margin-bottom: 2em;
    }

    .rightTextBox {
        background-color: rgba(0, 91, 150, 0.8);
        padding: 2.3em 1.2em;
        max-width: 400px;
        box-sizing: border-box;
        width: 100%;
        min-width: 200px;
        white-space: pre-wrap;
        z-index: 1
    }
}


@media (min-width: 480px) {
    .leftTextBox {
        position: absolute;
        background-color: rgba(0, 35, 102, 0.8);
        padding: 5.0em 5.0em;
        max-width: 500px;
        box-sizing: border-box;
        top: 20%;
        left: 15%;
        width: 40%;
        min-width: 200px;
        white-space: pre-wrap;
        z-index: 1
    }

    .rightTextBox {
        position: absolute;
        background-color: rgba(0, 91, 150, 0.8);
        padding: 5.0em 5.0em;
        max-width: 500px;
        box-sizing: border-box;
        top: 50%;
        right: 15%;
        width: 40%;
        min-width: 200px;
        white-space: pre-wrap;
        z-index: 1;
    }
}