.message-segment{
    background-color: #FFFFFF !important;
    padding: 1em !important;
    box-sizing: border-box;
}

@media (max-width: 480px) {
    .presidentMessageWrapper  {
        display: flex;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 1em;
        box-sizing: border-box;
        width: 100%;
        border-radius: 7px;
    }
}

@media (min-width: 480px) {
    .presidentMessageWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 5em;
        box-sizing: border-box;
        width: 60%;
        margin-right: -100%;
        border-radius: 5px;
    }

    .presidentMessageWrapper.slideRight {
        transition: margin-right 1s;
        margin-right: -40%;
    }
}
