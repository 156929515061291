@import "../../asset/font/font.css";

@keyframes typing {
    from {
        width: 0
    }
    to {
        width: 100%
    }
}


@keyframes caretBlink {
    from, to {
        border-color: transparent
    }
    50% {
        border-color: orange;
    }
}

.initialSubText{
    width: 0;
}

.removeCursor{
    
}

.subText1 {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: typing 0.6s steps(6, end);
    animation-delay: 0.5s;
}

.subText2 {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: typing 0.2s steps(2, end);
    animation-delay: 1.1s;
}


.subText3 {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: typing 0.4s steps(4, end);
    animation-delay: 1.3s;
}


.subText4 {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: typing 0.7s steps(7, end);
    animation-delay: 1.7s;
}


.mainText {
    font-family: 'Bans';
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    /*border-right: .1em solid orange; !* The typwriter cursor *!*/
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    /*letter-spacing: .15em; !* Adjust as needed *!*/
    /*animation: typing 1.5s steps(3, end),*/
    /*caretBlink .45s step-end infinite;*/
    /*animation-delay: 0.5s;*/
}

.removed {
    display: none;
}
