@media (max-width: 480px) {
    .messageWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 1em;
        box-sizing: border-box;
        width: 100%;
        border-radius: 7px;
    }
}

@media (min-width: 480px) {
    .messageWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 5em;
        box-sizing: border-box;
        width: 60%;
        margin-left: -100%;
        border-radius: 5px;
    }

    .messageWrapper.slideLeft {
        transition: margin-left 1s;
        margin-left: -40%;
    }
}


.message-segment {
    background-color: #FFFFFF !important;
    padding: 1em !important;
    box-sizing: border-box;
}

.messageFromProfessor {
    line-height: 1.9 !important;
}

@media (max-width: 480px) {
    .mainProfile{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 3em;
    }

    .mainProfile .image{
        width: 40%;
        margin-right: 3em;
    }

    .mainProfile .name {
        margin-bottom: 1em;
        font-size: 1.4em;
        color: #FFFFFF;
    }

    .mainProfile .title {
        font-size: 1em;
        color: #FFFFFF;
    }

}


@media (min-width: 480px) {

    .mainProfile .image{
        max-width: 130px !important;
        min-width: 100px !important;
        margin-bottom: 1.7em;
        color: #FFFFFF;
        margin-right: 3em;
    }

    .mainProfile .name {
        margin-bottom: 1em;
        font-size: 1.4em;
        color: #FFFFFF;
    }

    .mainProfile .title {
        font-size: 1em;
        color: #FFFFFF;
    }

}