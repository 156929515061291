@media (max-width: 480px) {
    .contacts {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

@media (min-width: 480px) {
    .contacts {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}

span {
    padding: 5px;
}