.GnBWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.gnbItem {
    cursor: pointer;
}

.whiteText{
    color: #FFFFFF !important;
}

